import React, { useEffect, useRef, useState } from 'react'
import styles from '../styles/AssetsList.module.scss'
import { useTranslation } from 'react-i18next';
import ReceiptLongRoundedIcon from '@mui/icons-material/ReceiptLongRounded';
import PaidRoundedIcon from '@mui/icons-material/PaidRounded';
import CurrencyBitcoinRoundedIcon from '@mui/icons-material/CurrencyBitcoinRounded';
import ListAltRoundedIcon from '@mui/icons-material/ListAltRounded';
import OilBarrelRoundedIcon from '@mui/icons-material/OilBarrelRounded';
import Select, { components } from 'react-select';
import { colourStylesCurrency } from './SelectCurrencyStyles';
import secureLocalStorage from 'react-secure-storage';
import altIcon from '../imgs/altIcon.svg'
export default function AssetsList({ favorites, setActiveAsset, activeAsset, toggleFavorite, currentRecords, activePrice, setActivePrice, theme, account, eurToUsd, usdToEur, handleClick, setSnackMessage, setSnackType }) {
  const { t, i18n } = useTranslation();
  const [search, setSearch] = useState('')
  const [selectAssets, setSelectAssets] = useState({ value: '999', label: t('AllAssets') })
  const [currentAssets, setCurrentAssets] = useState()
  const [isLoading, setIsLoading] = useState(true)

  const inputRef = useRef(null); // Создаем ref для input

  const handleSearchClick = () => {
    if (inputRef.current) {
      inputRef.current.focus(); // Устанавливаем фокус на input
    }
  };
  const handleImgError = (e) => {
    e.target.src = altIcon; // Подставляем альтернативную иконку при ошибке
  };
  const [displayedRecords, setDisplayedRecords] = useState([]);
  const [selectOptions, setSelectedOptions] = useState([
    { value: '999', label: t('AllAssets'), icon: <ListAltRoundedIcon /> },
    { value: '1', label: t('CryptoCurrencies'), icon: <CurrencyBitcoinRoundedIcon /> },
    { value: '2', label: t('Currencies'), icon: <PaidRoundedIcon /> },
    { value: '3', label: t('CorporateShares'), icon: <ReceiptLongRoundedIcon /> },
    { value: '6', label: t('CorporateSharesRu'), icon: <ReceiptLongRoundedIcon /> },
    { value: '4', label: t('OilAndGas'), icon: <OilBarrelRoundedIcon /> },
    { value: '5', label: t('PreciousMetals') },
  ])

  const assetTypeMap = {
    '1': 'crypto',
    '2': 'currency',
    '3': 'share',
    '4': 'oil_gas',
    '5': 'metal',
    '6': 'ruShare',

  };
  useEffect(() => {
    if (i18n.language == 'ru') {
      setSelectAssets({ value: '999', label: t('AllAssets'), icon: <ListAltRoundedIcon /> })
      if (favorites.length > 0 && !selectOptions.includes(el => el.value === '888')) {
        setSelectedOptions([
          { value: '999', label: t('AllAssets'), icon: <ListAltRoundedIcon /> },
          { value: '1', label: t('CryptoCurrencies'), icon: <CurrencyBitcoinRoundedIcon /> },
          { value: '2', label: t('Currencies'), icon: <PaidRoundedIcon /> },
          { value: '3', label: t('CorporateShares'), icon: <ReceiptLongRoundedIcon /> },
          { value: '6', label: t('CorporateSharesRu'), icon: <ReceiptLongRoundedIcon /> },
          { value: '4', label: t('OilAndGas'), icon: <OilBarrelRoundedIcon /> },
          { value: '5', label: t('PreciousMetals') },
          { value: '888', label: t('Favorites') },
        ])
      } else {
        setSelectedOptions([
          { value: '999', label: t('AllAssets'), icon: <ListAltRoundedIcon /> },
          { value: '1', label: t('CryptoCurrencies'), icon: <CurrencyBitcoinRoundedIcon /> },
          { value: '2', label: t('Currencies'), icon: <PaidRoundedIcon /> },
          { value: '3', label: t('CorporateShares'), icon: <ReceiptLongRoundedIcon /> },
          { value: '6', label: t('CorporateSharesRu'), icon: <ReceiptLongRoundedIcon /> },
          { value: '4', label: t('OilAndGas'), icon: <OilBarrelRoundedIcon /> },
          { value: '5', label: t('PreciousMetals') },
        ])
      }
    } else {
      setSelectAssets({ value: '999', label: t('AllAssets'), icon: <ListAltRoundedIcon /> })
      if (favorites.length > 0 && !selectOptions.includes(el => el.value === '888')) {
        setSelectedOptions([
          { value: '999', label: t('AllAssets'), icon: <ListAltRoundedIcon /> },
          { value: '1', label: t('CryptoCurrencies'), icon: <CurrencyBitcoinRoundedIcon /> },
          { value: '2', label: t('Currencies'), icon: <PaidRoundedIcon /> },
          { value: '3', label: t('CorporateShares'), icon: <ReceiptLongRoundedIcon /> },
          { value: '6', label: t('CorporateSharesRu'), icon: <ReceiptLongRoundedIcon /> },
          { value: '4', label: t('OilAndGas'), icon: <OilBarrelRoundedIcon /> },
          { value: '5', label: t('PreciousMetals') },
          { value: '888', label: t('Favorites') },
        ])
      } else {
        setSelectedOptions([
          { value: '999', label: t('AllAssets'), icon: <ListAltRoundedIcon /> },
          { value: '1', label: t('CryptoCurrencies'), icon: <CurrencyBitcoinRoundedIcon /> },
          { value: '2', label: t('Currencies'), icon: <PaidRoundedIcon /> },
          { value: '3', label: t('CorporateShares'), icon: <ReceiptLongRoundedIcon /> },
          { value: '6', label: t('CorporateSharesRu'), icon: <ReceiptLongRoundedIcon /> },
          { value: '4', label: t('OilAndGas'), icon: <OilBarrelRoundedIcon /> },
          { value: '5', label: t('PreciousMetals') },
        ])
      }
    }
  }, [i18n.language])

  useEffect(() => {
    if (favorites?.length > 0) {
      if (!selectOptions.find(el => el.value === '888')) {
        setSelectedOptions(prev => [...prev, { value: '888', label: t('Favorites') }])
      }
    } else {
      setSelectedOptions(prev => [...prev.filter(el => el.value !== '888')])
    }
  }, [favorites])

  const filterAssets = (records, search, selectedAssetType) => {
    return records.filter(record => {
      const isFavorite = favorites?.includes(record.Id);
      const type = assetTypeMap[selectedAssetType.value] || '';
      const matchesType = selectedAssetType.value === '888' ? isFavorite : (selectedAssetType.value === '999' || record.Type === type);
      const matchesSearch = !search || record.Title.toLowerCase().includes(search.toLowerCase()) || record.Subtitle.toLowerCase().includes(search.toLowerCase());
      return matchesType && matchesSearch;
    });
  };

  useEffect(() => {
    const filteredAssets = filterAssets(currentRecords, search, selectAssets);
    setDisplayedRecords(filteredAssets);
  }, [currentRecords, search, selectAssets, favorites]);

  useEffect(() => {
    if (!activeAsset) setActiveAsset(currentRecords.find(el => el.Title === 'Bitcoin'))
  }, [currentRecords]);

  useEffect(() => {
    if (favorites?.length === 0) {
      setSelectAssets({ value: '999', label: t('AllAssets') })
    }
  }, [favorites])

  const CustomOption = (props) => {
    const { innerProps, innerRef } = props;
    return (
      <div ref={innerRef} {...innerProps} className={props.isSelected ? styles.custom_option_selected : styles.custom_option}>
        <div className={styles.option} style={{ color: props.data.color, backgroundColor: props.data.background, width: 'auto' }}>
          {props.data.label}
        </div>
      </div>)
  }


  const DropdownIndicator = ({ children, ...props }) => (
    <components.DropdownIndicator {...props}>
      <div className={styles.single_value_container}>
        <div className={styles.custom_icon}>
          <svg width={"1.7vh"} height={"1.6vh"} viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M13.5003 6L8.83366 10L4.16699 6" stroke="#A0A0AB" strokeLinecap="round" strokeLinejoin="round" />
          </svg>
        </div>
        {children}
      </div>
    </components.DropdownIndicator>
  );
  return (
    <div className={styles.assets_list}>
      <div className={styles.select_search}>
        <div className={styles.select}>
          <Select
            closeMenuOnSelect={true}
            defaultValue={{ value: '999', label: t('AllAssets') }}
            value={selectAssets}
            styles={colourStylesCurrency(theme)}
            options={selectOptions}
            onChange={(e) =>
              setSelectAssets({ value: e.value, label: e.label })
            }
            components={{ DropdownIndicator, IndicatorSeparator: () => null, Option: CustomOption }}
          />
        </div>
        <div className={styles.search} onClick={handleSearchClick}>
          <svg xmlns="http://www.w3.org/2000/svg" width={'1.6vh'} height={'1.6vh'} viewBox="0 0 16 16" fill="none">
            <g clipPath="url(#clip0_436_4317)">
              <circle cx="7.66634" cy="7.66683" r="6.33333" stroke="#51525C" />
              <path d="M13.333 13.3335L14.6663 14.6668" stroke="#51525C" strokeLinecap="round" />
            </g>
            <defs>
              <clipPath id="clip0_436_4317">
                <rect width={16} height={16} fill="white" />
              </clipPath>
            </defs>
          </svg>
          <input ref={inputRef} placeholder={t('Search')} onChange={e => setSearch(e.target.value)} />
        </div>
      </div>
      <div className={styles.assets}>
        <div className={styles.assets_title}>
          <span className={styles.name}>{t('Asset')}</span>
          <span className={styles.price}>{t('Price')}</span>
          <span className={styles.percent}>{t('Change 24H')}</span>
        </div>
        <div className={styles.assets_body}>
          {displayedRecords.map(el =>
            <div className={styles.asset} key={el.Id} onClick={() => {
              if (el.MarketClosed && el.MarketClosed) {
                handleClick()
                setSnackMessage(t('MarketClosed'))
                setSnackType('error')
                return
              } else {
                setActiveAsset(el)
              }
            }}>
              <span className={styles.title_icon}>
                <img src={`https://cdn.jsdelivr.net/gh/vadimmalykhin/binance-icons/crypto/${el.Subtitle.slice(1).slice(0, -1).toLowerCase()}.svg`} alt='icon'
                  onError={handleImgError} />
                  <span>
                    {el.Subtitle.slice(1).slice(0, -1) === 'USDC'?el.Subtitle.slice(1).slice(0, -1):el.Subtitle.slice(1).slice(0, -1).replace('USDT','').replace('USD','')}
                    <p>
                      {el.Type === 'crypto'?'USDT':"USD"}
                    </p>
                  </span>
              </span>
              {el.MarketClosed && el.MarketClosed === 1 ? <span className={styles.price} style={{display:'flex',alignItems:'center',gap:'0.8vh'}}>
                <svg xmlns="http://www.w3.org/2000/svg" width={'1.6vh'} height={'1.6vh'} viewBox="0 0 16 16" fill="none">
                  <path fillRule="evenodd" clipRule="evenodd" d="M3.49967 6.70324V5.3335C3.49967 2.84821 5.51439 0.833496 7.99967 0.833496C10.485 0.833496 12.4997 2.84821 12.4997 5.3335V6.70324C13.2428 6.75874 13.7268 6.89886 14.0806 7.25262C14.6663 7.8384 14.6663 8.78121 14.6663 10.6668C14.6663 12.5524 14.6663 13.4953 14.0806 14.081C13.4948 14.6668 12.552 14.6668 10.6663 14.6668H5.33301C3.44739 14.6668 2.50458 14.6668 1.91879 14.081C1.33301 13.4953 1.33301 12.5524 1.33301 10.6668C1.33301 8.78121 1.33301 7.8384 1.91879 7.25262C2.27255 6.89886 2.7565 6.75874 3.49967 6.70324ZM4.49967 5.3335C4.49967 3.4005 6.06668 1.8335 7.99967 1.8335C9.93267 1.8335 11.4997 3.4005 11.4997 5.3335V6.66921C11.2443 6.66683 10.9674 6.66683 10.6663 6.66683H5.33301C5.032 6.66683 4.75502 6.66683 4.49967 6.66921V5.3335Z" fill="#F63D68" />
                </svg>
                <span style={{ opacity: "0.4" }}>{t('Closed')}</span></span> : <span className={styles.price}>{account?.AccountCurrency == "EUR" ? "€" : "$"}{parseFloat((el.Price * (account?.AccountCurrency === "EUR" ? eurToUsd : 1)))}</span>}
              <span className={styles.percent_icon}>
                <span className={`${styles.percent} ${el.Tendency.includes('-') ? '' : styles.plus}`} style={{ opacity: el.MarketClosed && el.MarketClosed === 1 ? '0.3' : "1" }}>
                  {el.Tendency}
                </span>
                {
                  favorites?.includes(el.Id) ?
                    <svg onClick={() => toggleFavorite(el.Id)} xmlns="http://www.w3.org/2000/svg" width={'1.6vh'} height={'1.6vh'} viewBox="0 0 16 16" fill="none">
                      <path d="M6.10178 3.60575C6.94622 2.09091 7.36843 1.3335 7.99967 1.3335C8.63092 1.3335 9.05313 2.09091 9.89757 3.60575L10.116 3.99765C10.356 4.42812 10.476 4.64335 10.663 4.78537C10.8501 4.92738 11.0831 4.9801 11.5491 5.08553L11.9733 5.18152C13.6131 5.55253 14.433 5.73804 14.6281 6.36532C14.8231 6.99259 14.2642 7.64621 13.1463 8.95345L12.8571 9.29164C12.5394 9.66312 12.3806 9.84886 12.3091 10.0786C12.2376 10.3084 12.2617 10.5562 12.3097 11.0519L12.3534 11.5031C12.5224 13.2472 12.6069 14.1193 12.0962 14.507C11.5856 14.8947 10.8179 14.5412 9.28256 13.8343L8.88535 13.6514C8.44906 13.4505 8.23091 13.3501 7.99967 13.3501C7.76844 13.3501 7.55029 13.4505 7.114 13.6514L6.71679 13.8343C5.18146 14.5412 4.41379 14.8947 3.90311 14.507C3.39242 14.1193 3.47693 13.2472 3.64594 11.5031L3.68966 11.0519C3.73769 10.5562 3.76171 10.3084 3.69025 10.0786C3.61879 9.84886 3.45996 9.66312 3.14229 9.29164L2.85307 8.95345C1.73517 7.64621 1.17622 6.99259 1.37129 6.36532C1.56635 5.73804 2.38625 5.55253 4.02603 5.18152L4.45027 5.08553C4.91624 4.9801 5.14923 4.92738 5.3363 4.78537C5.52338 4.64335 5.64336 4.42812 5.88332 3.99765L6.10178 3.60575Z" fill="#E9B90E" />
                    </svg>
                    :
                    <svg onClick={() => toggleFavorite(el.Id)} xmlns="http://www.w3.org/2000/svg" width={'1.6vh'} height={'1.6vh'} viewBox="0 0 16 16" fill="none">
                      <g clipPath="url(#clip0_436_6882)">
                        <path d="M6.10178 3.60575C6.94622 2.09091 7.36843 1.3335 7.99967 1.3335C8.63092 1.3335 9.05313 2.09091 9.89757 3.60575L10.116 3.99765C10.356 4.42812 10.476 4.64335 10.663 4.78537C10.8501 4.92738 11.0831 4.9801 11.5491 5.08553L11.9733 5.18152C13.6131 5.55253 14.433 5.73804 14.6281 6.36532C14.8231 6.99259 14.2642 7.64621 13.1463 8.95345L12.8571 9.29164C12.5394 9.66312 12.3806 9.84886 12.3091 10.0786C12.2376 10.3084 12.2617 10.5562 12.3097 11.0519L12.3534 11.5031C12.5224 13.2472 12.6069 14.1193 12.0962 14.507C11.5856 14.8947 10.8179 14.5412 9.28256 13.8343L8.88535 13.6514C8.44906 13.4505 8.23091 13.3501 7.99967 13.3501C7.76844 13.3501 7.55029 13.4505 7.114 13.6514L6.71679 13.8343C5.18146 14.5412 4.41379 14.8947 3.90311 14.507C3.39242 14.1193 3.47693 13.2472 3.64594 11.5031L3.68966 11.0519C3.73769 10.5562 3.76171 10.3084 3.69025 10.0786C3.61879 9.84886 3.45996 9.66312 3.14229 9.29164L2.85307 8.95345C1.73517 7.64621 1.17622 6.99259 1.37129 6.36532C1.56635 5.73804 2.38625 5.55253 4.02603 5.18152L4.45027 5.08553C4.91624 4.9801 5.14923 4.92738 5.3363 4.78537C5.52338 4.64335 5.64336 4.42812 5.88332 3.99765L6.10178 3.60575Z" stroke="#51525C" />
                      </g>
                      <defs>
                        <clipPath id="clip0_436_6882">
                          <rect width={16} height={16} rx={5} fill="white" />
                        </clipPath>
                      </defs>
                    </svg>
                }
              </span>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}
